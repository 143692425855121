import React from "react";

const Logout = () => {
	return (
		<div>
			<h3>Welcome!</h3>
			<button>Logout</button>
		</div>
	);
};

export default Logout;
